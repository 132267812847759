import React from 'react';
import { AviNumberSize, Text } from '@aviationexam/core';
import eliska from 'images/memojis/eliska_static.gif';
import diana from 'images/memojis/diana_static.gif';
import vera from 'images/memojis/vera_static.gif';
import marketa from 'images/memojis/marketa_static.gif';
import aviationexam from 'images/favicon.png';
import useStyles from './PostAuthor.styles';

const authorData = {
  eliska: {
    title: 'Eliška',
    src: eliska,
  },
  diana: {
    title: 'Diana',
    src: diana,
  },
  vera: {
    title: 'Věra',
    src: vera,
  },
  marketa: {
    title: 'Markéta',
    src: marketa,
  },
  aviationexam: {
    title: 'Aviationexam',
    src: aviationexam,
  },
};

interface PostAuthorProps extends React.ComponentPropsWithoutRef<'div'> {
  author: string;
  date?: string;
  size?: AviNumberSize;
}

export function PostAuthor({ author, date, className, size = 'md', ...others }: PostAuthorProps) {
  const { cx, classes } = useStyles({ size });
  const data = authorData[author] ?? authorData.aviationexam;

  return (
    <div className={cx(classes.root, className)} {...others}>
      <div className={classes.avatar}>
        <img src={data.src} alt={data.title} />
      </div>
      <div>
        <Text weight={700} size="md">
          {data.title}
        </Text>
        {date && (
          <Text color="dimmed" size="sm">
            {new Date(date).toLocaleDateString()}
          </Text>
        )}
      </div>
    </div>
  );
}
