import { AviNumberSize, createStyles } from '@aviationexam/core';

const sizes = {
  xs: 30,
  sm: 40,
  md: 50,
  lg: 60,
  xl: 70,
};

interface PostAuthorStyles {
  size: AviNumberSize;
}

export default createStyles((theme, { size }: PostAuthorStyles) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.sm,
  },
  avatar: {
    backgroundColor: theme.white,
    boxShadow: theme.shadows.md,
    borderRadius: 9999,
    width: theme.fn.size({ size, sizes }),
    height: theme.fn.size({ size, sizes }),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      maxWidth: '130%',
    },
  },
}));
