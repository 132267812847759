import React from 'react';
import { Link } from 'gatsby';
import { Grid, Col, Title, Group, ActionIcon } from '@aviationexam/core';
import { ArrowLeftIcon, ArrowRightIcon } from '@aviationexam/icons';
import { BlogPostNode } from 'types/blog';
import { PostCard } from './PostCard';

interface PostListProps {
  title: string;
  posts: BlogPostNode[];
  nextPage?: string;
  previousPage?: string;
}

export function PostList({ title, posts, nextPage, previousPage }: PostListProps) {
  return (
    <Grid gutter="xl" my="xl">
      <Col span={12}>
        <Title order={1}>{title}</Title>
      </Col>
      {posts.map((post, i) => {
        const route = post.route || post.alternateUrls.find(a => a.locale === 'en')?.route;
        if (!route) {
          return null;
        }
        return (
          <Col key={post.id} span={12} xs={i % 8 === 0 ? 12 : 6} md={i % 8 === 0 ? 12 : 4}>
            <PostCard
              title={post.title}
              author={post.author}
              date={post.createdDate}
              locale={post.locale}
              route={route}
              description={post.description}
              featuredImg={post.featuredImg}
              featured={i % 8 === 0}
            />
          </Col>
        );
      })}
      <Col span={12}>
        {(nextPage || previousPage) && (
          <Group position="right">
            {nextPage && (
              <ActionIcon<typeof Link>
                component={Link}
                to={nextPage}
                size="xl"
                color="blue"
                variant="filled"
              >
                <ArrowLeftIcon />
              </ActionIcon>
            )}
            {previousPage && (
              <ActionIcon<typeof Link>
                component={Link}
                to={previousPage}
                size="xl"
                color="blue"
                variant="filled"
              >
                <ArrowRightIcon />
              </ActionIcon>
            )}
          </Group>
        )}
      </Col>
    </Grid>
  );
}
