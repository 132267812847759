import { createStyles } from '@aviationexam/core';
import { HEADER_HEIGHT } from 'components/Layout/Layout.styles';

export default createStyles(theme => ({
  root: {
    position: 'sticky',
    width: '100%',
    backgroundColor: theme.colors.blue[9],
    top: HEADER_HEIGHT,
    zIndex: 40,
  },
  link: {
    fontSize: theme.fontSizes.sm,
    padding: theme.spacing.xs,
    color: theme.white,
    textTransform: 'uppercase',
    fontWeight: 600,
    borderBottom: '2px solid transparent',
    transition: 'all 0.3s ease-in-out',
    opacity: 0.65,
    '&:hover': {
      opacity: 1,
    },
  },
  linkActive: {
    opacity: 1,
    borderColor: theme.white,
  },
}));
