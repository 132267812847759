import { createStyles } from '@aviationexam/core';

interface PostCardStyles {
  featured: boolean;
}

export default createStyles((theme, { featured }: PostCardStyles) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    textDecoration: 'none',
    height: '100%',
    boxShadow: theme.shadows.lg,
    border: `1px solid ${theme.colors.gray[1]}`,
  },
  imageWrapper: {
    ...(featured && {
      width: '100%',
      [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        width: '50%',
      },
      [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
        width: '65%',
      },
    }),
    ...(!featured && {
      width: '100%',
    }),
  },
  description: {
    padding: theme.spacing.lg,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...(featured && {
      width: '100%',
      [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        width: '50%',
      },
      [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
        width: '35%',
      },
    }),
    ...(!featured && {
      width: '100%',
    }),
  },
  title: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 600,
    color: theme.colors.gray[8],
  },
  author: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    padding: [theme.spacing.xs, 0].map(p => `${p}px`).join(' '),
  },
}));
