import React from 'react';
import { Link } from 'gatsby';
import { Container, ScrollArea, Group, Text } from '@aviationexam/core';
import useStyles from './CategoryNavigation.styles';

interface CategoryNavigationProps extends React.ComponentPropsWithoutRef<'div'> {
  categories: { title: string; route: string }[];
  activeCategoryRoute?: string;
}

export function CategoryNavigation({
  categories,
  activeCategoryRoute,
  className,
  ...others
}: CategoryNavigationProps) {
  const { cx, classes } = useStyles();

  return (
    <div className={cx(classes.root, className)} {...others}>
      <Container size="xl">
        <ScrollArea>
          <Group position="center">
            {categories.map(category => (
              <Text<typeof Link>
                component={Link}
                to={category.route}
                className={cx(classes.link, {
                  [classes.linkActive]: category.route === activeCategoryRoute,
                })}
              >
                {category.title}
              </Text>
            ))}
          </Group>
        </ScrollArea>
      </Container>
    </div>
  );
}
