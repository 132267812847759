import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import { Text, Title } from '@aviationexam/core';
import { Locale } from 'features/localization';
import { PostAuthor } from './PostAuthor';
import useStyles from './PostCard.styles';

interface PostCardProps {
  title: string;
  author: string;
  date: string;
  route: string;
  description: string;
  locale: Locale;
  featuredImg: ImageDataLike;
  featured?: boolean;
  className?: string;
}

export function PostCard({
  title,
  author,
  date,
  route,
  description,
  locale,
  featuredImg,
  featured = false,
  className,
  ...others
}: PostCardProps) {
  const { cx, classes } = useStyles({ featured });
  const image = getImage(featuredImg);

  return (
    <Link className={cx(classes.root, className)} to={route} hrefLang={locale} {...others}>
      <div className={classes.imageWrapper}>
        <GatsbyImage image={image} alt={title} />
      </div>
      <div className={classes.description}>
        <div>
          <Title order={4} className={classes.title}>
            {title}
          </Title>
          <Text component="p" color="dimmed">
            {description}
          </Text>
        </div>
        <div className={classes.author}>
          <PostAuthor author={author} date={date} />
        </div>
      </div>
    </Link>
  );
}
