import React from 'react';
import { graphql, PageProps, Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Container, Grid, Col, Title, Text, Badge, Group, ActionIcon } from '@aviationexam/core';
import { FacebookIcon, LinkedInIcon, TwitterIcon } from '@aviationexam/icons';
import { Renderer } from '@aviationexam/rte';
import { SEO } from 'components';
import { Locale, usePublicTranslation } from 'features/localization';
import { BlogPostNode } from 'types/blog';
import { PostAuthor } from './components';
import useStyles from './Post.styles';

interface DataType {
  apiBlogPost: BlogPostNode;
}

interface Context {
  locale: Locale;
}

export default function Post({
  data: { apiBlogPost: post },
  pageContext: { locale },
}: PageProps<DataType, Context>) {
  const { t } = usePublicTranslation();
  const location = useLocation();
  const { classes } = useStyles();
  const featuredImage = getImage(post.featuredImg);
  return (
    <>
      <SEO title={post.title} description={post.description} lang={locale}>
        {post.alternateUrls.map(alternateUrl => (
          <link
            key={alternateUrl.route}
            rel="alternate"
            hrefLang={alternateUrl.locale}
            href={alternateUrl.route}
          />
        ))}
      </SEO>
      <div className={classes.featuredImageWrapper}>
        <GatsbyImage image={featuredImage} alt={post.title} />
      </div>
      <Container>
        <Grid gutter="xl" className={classes.grid}>
          <Col span={12}>
            <Title order={1} align="center">
              {post.title}
            </Title>
            <Text component="p" align="center">
              {post.description}
            </Text>
          </Col>
          <Col span={12}>
            <div className={classes.authorWrapper}>
              <PostAuthor author={post.author} date={post.createdDate} size="lg" />
            </div>
          </Col>
          <Col span={12}>
            <Renderer value={post.body} />
          </Col>
          <Col span={12} xs={6}>
            <Group>
              {post.tags.map(tag => (
                <Badge<typeof Link>
                  key={tag.route}
                  to={tag.route}
                  component={Link}
                  radius="xs"
                  size="lg"
                  className={classes.tag}
                >
                  #{tag.title}
                </Badge>
              ))}
            </Group>
          </Col>
          <Col span={12} xs={6}>
            <Group position="right">
              <ActionIcon<'a'>
                component="a"
                color="gray"
                variant="filled"
                href={`https://twitter.com/intent/tweet?url=${location.href}`}
                target="_blank"
                rel="noreferrer"
                title={t('actions.shareWith', { what: 'Twitter' })}
                size="lg"
              >
                <TwitterIcon size={26} />
              </ActionIcon>
              <ActionIcon<'a'>
                component="a"
                color="gray"
                variant="filled"
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${location.href}`}
                target="_blank"
                rel="noreferrer"
                title={t('actions.shareWith', { what: 'LinkedIn' })}
                size="lg"
              >
                <LinkedInIcon size={26} />
              </ActionIcon>
              <ActionIcon<'a'>
                component="a"
                color="gray"
                variant="filled"
                href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
                target="_blank"
                rel="noreferrer"
                title={t('actions.shareWith', { what: 'Facebook' })}
                size="lg"
              >
                <FacebookIcon size={26} />
              </ActionIcon>
            </Group>
          </Col>
        </Grid>
      </Container>
    </>
  );
}

export const pageQuery = graphql`
  query BlogPost($id: String!) {
    apiBlogPost(id: { eq: $id }) {
      title
      description
      body
      author
      createdDate
      alternateUrls {
        locale
        route
      }
      tags {
        title
        route
      }
      featuredImg {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [WEBP, AUTO]
          )
        }
      }
    }
  }
`;
