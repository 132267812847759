import { createStyles } from '@aviationexam/core';

export default createStyles(theme => ({
  featuredImageWrapper: {
    width: '100%',
    maxWidth: 1100,
    margin: '0 auto',
  },
  authorWrapper: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    padding: theme.spacing.xs,
    display: 'flex',
    justifyContent: 'center',
  },
  grid: {
    margin: '1em 0',
  },
  tag: {
    textTransform: 'none',
    cursor: 'pointer',
  },
}));
